<template>
  <b-card
    class="overflow-hidden p-0"
    no-body
  >
    <b-table
      responsive
      :items="expenses"
      :fields="isMaterial ? fieldsForMaterial : fields"
      small
      sort-by="expense_date"
      sort-desc
    >

      <template #cell(folio)="data">
        <div class="text-primary font-weight-bolder">
          #{{ data.value }}
        </div>
      </template>

      <template #cell(name)="data">
        <router-link
          :to="{
            name: 'expensesByGroup',
            params: {
              projectId: $route.params.projectId,
              groupedExpenseId: data.item.grouped_expense_id,
            },
          }"
          class="text-primary font-weight-bolder"
        >
          {{ data.value }}
        </router-link>
      </template>

      <template #cell(description)="data">
        <feather-icon
          :id="`tooltip-target-${data.index}`"
          icon="InfoIcon"
          size="15"
          stroke="black"
          @click="viewInfoExpenseModal(data.item)"
        />
        <!-- <b-tooltip
          :target="`tooltip-target-${data.index}`"
          triggers="hover"
          boundary-padding="10"
        >
          <span>
            {{ data.value }}
          </span>
        </b-tooltip> -->
      </template>

      <template #cell(expense_date)="data">
        <div>{{ data.value | date }}</div>
      </template>

      <template #cell(quantity)="data">
        <div>{{ data.value }}</div>
      </template>

      <template #cell(unit_type)="data">
        <div>{{ $tc(unitsFilter(data.value), data.item.quantity) }}</div>
      </template>

      <template #cell(unit_price)="data">
        <div>${{ data.value | money }}</div>
      </template>

      <template #cell(total)="data">
        <div>${{ data.value | money }}</div>
      </template>

      <template #cell(expense_status)="data">
        <b-dropdown
          :id="data.item.id"
          class="p-0"
          size="md"
          dropright
          no-caret
          variant="outline-none"
        >
          <template #button-content>
            <b-badge
              :variant="statusColor(data.value)"
              class="px-1"
            >{{ $t(data.value) }}
              <feather-icon
                icon="ChevronDownIcon"
                size="20"
              />
            </b-badge>
          </template>
          <b-dropdown-item
            v-for="(status, key) in expenseStatusOptions"
            :key="key"
            @click="changeStatus(data.item.id, status.value)"
          >
            {{ $t(status.value) }}
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <template #cell(tools)="data">
        <b-dropdown
          id="dropdown-1"
          class="p-0"
          size="sm"
          dropright
          no-caret
          variant="outline-none"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="18"
              color="#000"
            />
          </template>
          <b-dropdown-item @click="removeExpense(data.item.id)">
            <span class="text-danger">
              {{ $t('delete') }}
            </span>
          </b-dropdown-item>
          <b-dropdown-item
            @click="openEditModal(data.item)"
          >{{ $t('edit') }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-modal
      id="edit-expense"
      :title="$t('edit')"
      @ok="editExpense()"
      @cancel="expenseToBeEdited = {}"
    >
      <b-row>
        <b-col sm="12">
          <b-form-group
            class="mb-0"
            :label="$t('date')"
            label-for="input-sm"
          >
            <b-form-datepicker
              v-model="expenseToBeEdited.expense_date"
              :date-format-options="{
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
              }"
              size="lg"
              class="font-weight-bolder"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group
            class="mb-0"
            :label="$t('unitPrice')"
            label-for="input-sm"
          >
            <b-form-input
              v-model="expenseToBeEdited.unit_price"
              min="1"
              class="qty-field"
              autocomplete="off"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group
            class="mb-0"
            :label="$t('quantity')"
            label-for="input-sm"
          >
            <b-form-input
              v-model="expenseToBeEdited.quantity"
              min="1"
              class="qty-field"
              autocomplete="off"
              type="number"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="view-expense" :title="$t('dataSaved')" hide-footer>
      <b-row>
        <b-col md="6" class="mb-1">
          <b-form-group class="mb-0" :label="$t('date')" label-for="input-sm">
            <p class="font-weight-bold mb-0">{{ formatDate(expenseToBeEdited.expense_date) }}</p>
          </b-form-group>
        </b-col>
        <b-col md="6" class="mb-1">
          <b-form-group class="mb-0" :label="$t('category')" label-for="input-sm">
            <div v-if="expenseToBeEdited && expenseToBeEdited.expense_type_catalog">
              <p class="mb-0">{{ expenseToBeEdited.expense_type_catalog.name }}</p>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6" class="mb-1">
          <b-form-group class="mb-0" :label="$t('name')" label-for="input-sm">
            <p class="mb-0">{{ expenseToBeEdited.name }}</p>
          </b-form-group>
        </b-col>
        <b-col md="6" class="mb-1">
          <b-form-group class="mb-0" :label="$t('brand')" label-for="input-sm">
            <p class="mb-0">{{ expenseToBeEdited.brand }}</p>
          </b-form-group>
        </b-col>
        <b-col md="12" class="mb-1">
          <b-form-group class="mb-0" :label="$t('description')" label-for="input-sm">
            <p class="mb-0">{{ expenseToBeEdited.description }}</p>
          </b-form-group>
        </b-col>
        <b-col md="6" class="mb-1">
          <b-form-group class="mb-0" :label="$t('supplier')" label-for="input-sm">
            <p class="mb-0">{{ expenseToBeEdited.supplier }}</p>
          </b-form-group>
        </b-col>
        <b-col md="6" class="mb-1">
          <b-form-group class="mb-0" :label="$t('quantity')" label-for="input-sm">
            <p class="mb-0">{{ expenseToBeEdited.quantity }}</p>
          </b-form-group>
        </b-col>
        <b-col md="6" class="mb-1">
          <b-form-group class="mb-0" :label="$t('unitPrice')" label-for="input-sm">
            <p class="mb-0">{{ formatCurrency(expenseToBeEdited.unit_price) }}</p>
          </b-form-group>
        </b-col>
        <b-col md="6" class="mb-1">
          <b-form-group class="mb-0" :label="$t('amount-2')" label-for="input-sm">
            <p class="mb-0">{{ formatCurrency(expenseToBeEdited.total) }}</p>
          </b-form-group>
        </b-col>
        <!-- <b-col md="12" class="mb-1">
          <b-form-group class="mb-0" :label="$t('status')" label-for="input-sm">
            <p class="mb-0">{{ expenseToBeEdited.expense_status }}</p>
          </b-form-group>
        </b-col> -->
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTable,
  },
  props: {
    expenses: {
      type: Array,
      default() {
        return []
      },
    },
    isMaterial: {
      type: Boolean,
      default() {
        return true
      },
    },
  },
  data() {
    return {
      expenseToBeEdited: {
        expense_type_catalog: {},
      },
      expenseStatusOptions: [
        { value: 'paid' },
        { value: 'credit' },
        { value: 'expired' },
      ],
      fieldsForMaterial: [
        {
          key: 'expense_date',
          label: this.$t('date'),
        },
        {
          key: 'expense_type_catalog.name',
          label: this.$t('category'),
        },
        {
          key: 'name',
          label: this.$t('name'),
        },
        {
          key: 'total',
          label: this.$t('amount-2'),
        },
        {
          key: 'description',
          label: this.$t('description'),
          tdClass: 'text-center',
        },
        {
          key: 'supplier',
          label: this.$t('supplier'),
        },
        // {
        //   key: 'expense_create_type',
        //   label: this.$t('type'),
        // },
        {
          key: 'quantity',
          label: this.$t('quantity'),
        },
        {
          key: 'unit_type',
          label: this.$t('unit'),
        },
        {
          key: 'unit_price',
          label: this.$t('unitPrice'),
        },
        // {
        //   key: 'total',
        //   label: this.$t('amount-2'),
        // },
        {
          key: 'expense_status',
          label: this.$t('status'),
        },
        {
          key: 'tools',
          label: this.$t('tools'),
        },
      ],
      fields: [
        {
          key: 'expense_date',
          label: this.$t('date'),
        },
        {
          key: 'expense_type_catalog.name',
          label: this.$t('category'),
        },
        {
          key: 'name',
          label: this.$t('name'),
        },
        {
          key: 'total',
          label: this.$t('amount-2'),
        },
        // {
        //   key: 'brand',
        //   label: this.$t('brand'),
        // },
        {
          key: 'description',
          label: this.$t('description'),
          tdClass: 'text-center',
        },
        // {
        //   key: 'supplier',
        //   label: this.$t('supplier'),
        // },
        // {
        //   key: 'expense_create_type',
        //   label: this.$t('type'),
        // },
        // {
        //   key: 'quantity',
        //   label: this.$t('quantity'),
        // },
        // {
        //   key: 'unit_price',
        //   label: this.$t('unitPrice'),
        // },
        {
          key: 'expense_status',
          label: this.$t('status'),
        },
        {
          key: 'tools',
          label: this.$t('tools'),
        },
      ],
    }
  },
  computed: {
    ...mapGetters('expenses', ['expensesPagination']),
    ...mapGetters('projects', ['project']),
    ...mapGetters('app', ['unitsFilter']),
  },
  methods: {
    ...mapActions('expenses', [
      'updateExpenseStatus',
      'fetchExpenses',
      'updateExpense',
      'deleteExpense',
    ]),
    changeStatus(expenseId, status) {
      this.updateExpenseStatus({ expenseId, status }).then(() => {
        this.fetchExpenses({
          projectId: this.$route.params.projectId,
          groupId: this.$route.params.groupedExpenseId,
          pagination: this.expensesPagination,
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('statusUpdated'),
            icon: 'SendIcon',
            variant: 'success',
          },
        })
      })
    },
    openEditModal(expense) {
      this.expenseToBeEdited = { ...expense }
      this.$bvModal.show('edit-expense')
    },
    editExpense() {
      this.updateExpense(this.expenseToBeEdited).then(() => {
        this.fetchExpenses({
          projectId: this.$route.params.projectId,
          groupId: this.$route.params.groupedExpenseId,
          pagination: this.expensesPagination,
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('expenseUpdated'),
            icon: 'SendIcon',
            variant: 'success',
          },
        })
      })
      this.$bvModal.hide('edit-expense')
    },
    removeExpense(expenseId) {
      this.deleteExpense(expenseId).then(() => {
        this.fetchExpenses({
          projectId: this.$route.params.projectId,
          groupId: this.$route.params.groupedExpenseId,
          pagination: this.expensesPagination,
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('expenseDeleted'),
            icon: 'SendIcon',
            variant: 'warning',
          },
        })
      })
      this.$bvModal.hide('edit-expense')
    },
    statusColor(status) {
      switch (status) {
        case 'paid':
          return 'success'
        case 'credit':
          return 'warning'
        case 'expired':
          return 'danger'
        default:
          return ''
      }
    },
    viewInfoExpenseModal(expense) {
      this.expenseToBeEdited = { ...expense }
      this.$bvModal.show('view-expense')
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString()
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(value)
    },
  },
}
</script>

<style lang="scss">
</style>
